import { useState, useEffect, useCallback } from "react";
import useSWR, { SWRConfiguration } from "swr";

export interface FetchError extends Error {
  status?: number;
}

// only alert about login redirect once;
let redirectAlerted = false;

export async function fetcher(input: RequestInfo, init?: RequestInit) {
  const resp = await fetch(input, init);

  if (!resp.ok) {
    if (resp.status === 401) {
      window.location.href = "/auth/login";
    }
    if (resp.status === 403) {
      const errBody = await resp.json();
      if (errBody.message && !redirectAlerted) {
        redirectAlerted = true;
        alert(errBody.message);
      }
      window.location.href = "/login.html";
    }

    let message = "Unknown Error";

    try {
      const errBody = await resp.json();
      if (errBody.message) {
        message = errBody.message;
      }
    } catch (jsonErr) {
      const e = jsonErr as Error;
      console.log("error parsing error response", e.stack);
    }
    const err: FetchError = new Error(message);
    err.status = resp.status;

    throw err;
  }

  return resp.json();
}

export default function useApi<T, Error = any>(
  path?: string | null,
  options?: SWRConfiguration
): {
  data: T | null;
  isValidating: boolean;
  revalidate: () => Promise<void>;
  error: Error | null;
} {
  const { data, error, isValidating, mutate } = useSWR<T, Error>(
    path ?? null,
    fetcher,
    options
  );

  async function revalidate() {
    await mutate();
  }

  return { data: data || null, isValidating, revalidate, error: error || null };
}

export function useDelete(
  path?: string | null,
  options?: {
    confirm: string;
    onSuccess?: () => void;
    onError?: (err: string) => void;
  }
) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { confirm, onError, onSuccess } = options || {};

  const deleteResource = useCallback(async () => {
    if (confirm && !window.confirm(confirm)) return;

    setIsDeleting(true);
    setError(null);

    try {
      if (!path) throw new Error("No path provided");

      await del(path);
      if (onSuccess) {
        onSuccess();
      }
    } catch (err: any) {
      if (onError) {
        onError(err.message);
      }
      setError(err.message);
    } finally {
      setIsDeleting(false);
    }
  }, [path, confirm, onSuccess, onError]);

  return { isDeleting, deleteResource, error };
}

export function useLocation<T>(orgId: string, locationId: string) {
  const { data: location, ...other } = useApi<T | null>(
    orgId && locationId
      ? `/api/organization/${orgId}/location/${locationId}`
      : null
  );
  return { location, ...other };
}

export async function post<T, R>(path: string, body: T): Promise<R> {
  return jsonRequest("POST", path, body);
}

export async function put<T, R>(path: string, body: T): Promise<R> {
  return jsonRequest("PUT", path, body);
}

export async function del<R>(path: string): Promise<R> {
  return jsonRequest("DELETE", path);
}

export async function patch<T, R>(path: string, body: T): Promise<R> {
  return jsonRequest("PATCH", path, body);
}

/**
 * Helper for posting to the operator json rest api
 * @param path
 * @param body
 */
async function jsonRequest<T, R>(
  method: string,
  path: string,
  body?: T
): Promise<R> {
  const res = await fetch(path, {
    body: body ? JSON.stringify(body) : undefined,
    method,
    headers: {
      "content-type": "application/json",
    },
  });

  if (!res.ok) {
    let errBody: { message?: string } | undefined;
    try {
      errBody = await res.json();
    } catch (err) {
      console.log("Couldn't parse error body", err);
    }
    const { message = res.statusText } = errBody || {};

    throw new Error(message);
  }

  return await res.json();
}

export function useSearchQuery<T>(path: string, query: Record<string, string>) {
  const [result, setResult] = useState<T>();
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<string>();
  const [revalidate, setRevalidate] = useState<number>(0);

  const params = new URLSearchParams(query);
  const paramsStr = params.toString();

  useEffect(() => {
    // using abort controller to prevent result races,
    // would be nice if the server could cancel the rpc too
    // but thats a lot more work
    const controller = new AbortController();
    const { signal } = controller;

    setIsSearching(true);
    setError(undefined);

    const handler = setTimeout(async () => {
      try {
        const res = await fetcher(path + "?" + paramsStr, { signal });
        setIsSearching(false);
        setResult(res);
      } catch (err) {
        const e = err as Error;
        if (e.name !== "AbortError") {
          setError(e.message);
        }
      }
    }, 200); // Debounce

    return () => {
      controller.abort();
      clearTimeout(handler);
      setIsSearching(false);
    };
  }, [path, paramsStr, revalidate]);

  return {
    result,
    isSearching,
    error,
    revalidate: () => {
      setRevalidate(revalidate + 1);
    },
  };
}
